'use client';

import { cn } from 'ui';

export const DashboardLayoutContainer = ({
  children,
  pathname,
}: {
  children: React.ReactNode;
  pathname: string;
}) => {
  console.log('pathname', pathname);

  return (
    <div
      className={cn('space-y-8 w-full', {
        'bg-stone-100 space-y-0': pathname?.includes('settings'),
      })}
    >
      {children}
    </div>
  );
};
